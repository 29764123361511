<script setup>
import { computed, defineProps, defineEmits, watch, onUnmounted } from "vue";

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  title: {
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: "default"
  },
  disableClose: {
    type: Boolean,
    default: false
  },
  width: {
    type: String,
    default: "auto"
  }
});
const emit = defineEmits(["toggle-modal"]);
const model = computed({
  get() {
    return props.showModal;
  },
  set(newValue) {
    emit("toggle-modal", newValue);
  }
});

const unwatch = watch(
  () => model.value,
  () => {
    if (model.value) {
      document.querySelector("body").style.overflow = "hidden";
      window.top.postMessage({ action: "dialogopen" }, "*");
    } else {
      document.querySelector("body").style.overflow = "inherit";
      window.top.postMessage({ action: "dialogclose" }, "*");
    }
  }
);

onUnmounted(() => unwatch());
</script>

<template>
  <v-dialog
    v-model="model"
    :width="width"
    :content-class="'bg-white dialog dialog-' + type"
    :persistent="disableClose"
    overlay-color="#000"
    overlay-opacity="0.5"
  >
    <div class="card card-custom overflow-hidden">
      <div v-if="title" class="card-header align-content-center">
        <div class="d-flex flex-center">
          <span class="text-h5">
            {{ title }}
          </span>
        </div>
        <div class="card-toolbar">
          <button
            size="sm"
            class="btn btn-secondary"
            @click="$emit('toggle-modal', disableClose)"
          >
            {{ $t("general.close") }}
          </button>
        </div>
      </div>
      <div class="card-body overflow-auto">
        <slot name="content" />
      </div>

      <div v-if="showFooter" class="card-footer">
        <slot name="footer" />
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="scss">
:deep(.dialog) {
  max-width: 80%;

  &.dialog-small {
    width: 600px;
  }
  &.dialog-default {
    width: 800px;
  }
  &.dialog-large {
    width: 1000px;
  }
}
</style>

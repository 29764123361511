<script setup>
import { computed, ref } from "vue";
import { useFeatureReporting } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureReporting";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";
import ExportJournal from "@/components/ExternalApps/SalesChannelManagementApp/components/Journal/ExportJournal.vue";
import FeatureReporting from "@/components/ExternalApps/SalesChannelManagementApp/components/Journal/FeatureReporting.vue";
import Card from "@/components/Tools/Card/Card.vue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import { useStore } from "@/core/services/store";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import SkeletonLoading from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/SkeletonLoading.vue";
import CategoryReporting from "@/components/ExternalApps/SalesChannelManagementApp/components/Journal/CategoryReporting.vue";

const store = useStore();
const { isAppInitialized } = useAppStatus();
const { isReportingEnabled } = useFeatureReporting();
const { features } = useFeatures();
const { selectFeature } = useFeatureArea();

const selectedJournal = ref("journal");

const journals = computed(() => {
  const temp = ["journal"];

  if (features.value.length > 0) {
    for (const name of features.value) {
      if (isReportingEnabled(name)) {
        temp.push(name);
      }
    }
  }

  return temp;
});

function selectJournal(item) {
  selectedJournal.value = item;
  selectFeature(item);
}
</script>

<template>
  <div>
    <Progressbar />
    <div class="pt-4">
      <div class="d-flex mb-4">
        <!--   START: BACK BUTTON   -->
        <button
          v-b-popover.hover="$t('salesChannelManagementApp.backToOverview')"
          class=""
          @click="$router.push({ name: 'salesChannelManagementApp' })"
        >
          <i class="fal fa-chevron-left" />
        </button>
        <!--   END: BACK BUTTON   -->
        <div v-if="isAppInitialized" class="h5 mb-0 ml-5 font-weight-bolder">
          {{
            store.getters.selectedIntegration.name.replace(
              store.getters.selectedApp.name,
              ""
            )
          }}
        </div>
      </div>

      <Card class="p-6">
        <div v-if="isAppInitialized">
          <div class="tabs d-flex justify-content-between mb-4">
            <PerfectScrollbar class="d-flex align-end">
              <div
                v-for="(item, i) in journals"
                :key="i"
                :class="{
                  active: selectedJournal === item
                }"
                class="tab-title py-2 px-4"
                @click="selectJournal(item)"
              >
                <span class="">{{
                  $t("salesChannelManagementApp.titles." + item)
                }}</span>
              </div>
            </PerfectScrollbar>
          </div>
          <div>
            <keep-alive>
              <ExportJournal v-if="selectedJournal === 'journal'" />

              <FeatureReporting
                v-else-if="
                  selectedJournal !== 'category' &&
                  selectedJournal !== 'journal'
                "
                :key="selectedJournal"
              />
              <CategoryReporting v-else-if="selectedJournal === 'category'" />
            </keep-alive>
          </div>
        </div>
        <div v-else>
          <SkeletonLoading />
        </div>
      </Card>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tabs {
  border-bottom: 1px solid #d0d5dd;

  .tab-title {
    color: $color-connect-text;
    font-weight: bold;
    border: none;
    cursor: pointer;
    white-space: nowrap;

    &.active,
    &:hover {
      box-shadow: 0 -2px 0 $color-connect-primary inset;
      color: $color-connect-primary;
      text-decoration: none;
      font-weight: bold;
    }

    &.active {
      &:hover {
        color: $color-connect-primary;
      }
    }

    &:hover {
      color: $color-connect-text;
    }
  }
}
</style>

import { computed, ref } from "vue";
import { useStore } from "@/core/services/store";
import { setValuesToSave } from "@/composables/useSalesChannelManagementApp";
import Presets from "@/components/Settings/Presets/presets";

const store = useStore();
const integrationsWithPresets = ref([]);

export const useIntegration = () => {
  const integrations = computed(() => {
    return store.getters.integrations;
  });
  const selectedIntegration = computed(() => store.getters.selectedIntegration);
  const selectedIntegrationName = computed(() =>
    selectedIntegration.value.name
      .replace(store.getters.selectedApp.name, "")
      .trim()
  );
  const selectedIntegrationSaveValues = computed(
    () => store.getters.valuesToSave
  );

  async function getIntegrationsWithPresets() {
    if (integrations.value?.length === 0) {
      integrationsWithPresets.value = [];
    }

    let presets = await getPresetConfigs(
      integrations.value.map(value => value.id)
    );

    const migrationChecks =
      store.getters["variables/configValueByName"]("migrationChecks")?.value;

    integrationsWithPresets.value = integrations.value.map(integration => ({
      ...integration,
      featureStatus: getActiveFeatures(
        presets.find(
          preset =>
            preset.project_id === integration.id && preset.name === "features"
        )?.value,
        presets.filter(
          preset =>
            preset.project_id === integration.id &&
            preset.name.includes("BaseSettings")
        )
      ),
      productiveMode:
        presets.find(
          preset =>
            preset.project_id === integration.id && preset.name === "baseData"
        )?.value.active ?? false,
      initialDataImportStatus:
        !migrationChecks?.[integration.id]?.migrationFinished ?? false
    }));
  }

  function getActiveFeatures(features = {}, baseSettings) {
    const activeFeatures = Object.keys(features).filter(
      key => features[key] === true
    );

    return mapFeaturesToValues(activeFeatures, baseSettings);
  }

  function mapFeaturesToValues(featuresArray, objectsArray) {
    const result = {};

    featuresArray.forEach(feature => {
      const object = objectsArray.find(
        obj => obj.name === `${feature}BaseSettings`
      );
      result[feature] = object ? object.value.active : null;
    });

    return result;
  }

  async function getPresetConfigs(projectIds) {
    const presetConfigFilter = [
      {
        key: "project_id",
        op: "in",
        value: projectIds
      }
    ];
    const presetConfigs = await Presets.getAll(
      { showAll: true, noPagination: true },
      presetConfigFilter
    );

    return presetConfigs?.data ?? [];
  }

  function formValueChange(value, feature, area = null, features = {}) {
    if (!feature || !value.name) return;

    const payload = {
      feature: feature,
      name: value.name,
      value: value.value,
      area: area,
      features: features
    };

    setValuesToSave(payload);
  }

  return {
    formValueChange,
    integrations,
    integrationsWithPresets,
    selectedIntegration,
    selectedIntegrationName,
    selectedIntegrationSaveValues,
    getIntegrationsWithPresets
  };
};
